import { createRouter, createWebHashHistory } from "vue-router";



const  routes = [

    {
        path: '/', 
        component: () => import('../components/HelloWorld.vue')
    },
    {
        path: '/about', 
        component: () => import('../components/About.vue')
    },
    {
        path: '/contact', 
        component: () => import('../components/Contact.vue')
    },
    {
        path: '/inicio', 
        component: () => import('../components/Inicio.vue')
    }

]


const router = createRouter({
    history: createWebHashHistory(),
    routes, 
  })

  export default router