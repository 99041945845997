<template>
   
      
    
<footer class="bg-white rounded-lg shadow w-full h-full dark:bg-gray-900 ">
    <div class="mx-auto p-4 md:py-8">
        
        <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023. All Rights Reserved.</span>

    </div>
</footer>


      
        

        
        
          
            
            
     </template>
            
            
    <script>
    export default {
    name: 'footer-i',
    
    
    }
    </script>
    
   
    <style>     
    </style>